import { Route, route, Router } from 'preact-router'
import RootRoute from './routes/Root'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import NotfoundScene from './screens/NotfoundScene'
import AccountRoute from './routes/AccountRoute'

const App = () => {

  return (
    <div>
      <Router>
        <AccountRoute path='/account/:rest*' />
        <AuthenticatedRoute
          path='/:rest*'
          login='/login'
          route={RootRoute}
        />
        <Route path='*' default component={NotfoundScene} />
      </Router>
    </div>

  )
}

export default App
