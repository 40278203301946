import { computed, observable, action } from 'mobx'
import { route } from 'preact-router';
export const KEY_STORAGE = 'XO0jvliyngURoyqeCNYsQ2vNE5G9LMGD'

class UserMobx {
    @observable isLogin = true;
    @observable access_token = "";
    @observable user_id = "";
    @observable role = "";
    @observable currentPath = "/";


    constructor() {
        this.onInitial();
    }

    @action
    async onInitial() {
        try {
            let storage = await localStorage.getItem(KEY_STORAGE);
            if (storage) {
                const parse = JSON.parse(storage);
                for (const key in parse) {
                    if (parse.hasOwnProperty(key)) {
                        const element = parse[key];
                        this[key] = element;
                    }
                }
            }
        } catch (error) {
            console.log('ERROR', error)
        }
    }

    @action
    async onLogin(userInfo) {
        try {
            const { isLogin, access_token, role, user_id } = userInfo;
            const obj = { isLogin, access_token, role, user_id }

            const hash = JSON.stringify(obj)

            await localStorage.setItem(KEY_STORAGE, hash);

            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const element = obj[key];
                    this[key] = element
                }
            }

            route('/pdPlan');

        } catch (error) {
            console.log("error mobx login -> ", error)
        }
    }

    @action
    async onLogout() {
        try {
            await localStorage.clear();
            this.access_token = "";

            route('/account/login');
        } catch (error) {
            console.log("error mobx logout -> ", error)
            window.location.href = ""
        }
    }

    @computed
    get returnToken() {
        return this.access_token
    }

    @computed
    get returnRole() {
        return this.role
    }
}



export default new UserMobx()